import React from 'react';

import { pageLinks } from '../data/pageLinks';

import Layout from '../components/v2/Layout';
import ContentBlock from '../components/v2/ContentBlock';
import SocialGuide from '../components/v2/SocialGuide';
import PageSection from '../components/v2/PageSection';
import BrandPartnership from '../components/v2/BrandPartnership';
import Image from '../components/v2/Image';
import ImageGrid from '../components/v2/ImageGrid';
import Tabs from '../components/v2/ui-blocks/Tabs';

import ImageScroller from '../components/v2/ui-blocks/ImageScroller';
import Socialpreveiw from '../components/v2/SocialPreview';
import PrevNextNavFooter from '../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter';

import placeholder from '../images/placeholder_500x500.png'

import LGP_Wishing_Chair_png from '../images/11. Playbook/lgp-landing-pages_mobility_header-banner-1920x824.jpg';

import backing_the_hawks_bay from '../images/11. Playbook/backing-the-hawks-bay.jpg';
import community_moover from '../images/11. Playbook/Community Moover-01.jpg';
import manukau_png from '../images/11. Playbook/manukau.png';

import number_plate_png from '../images/11. Playbook/Number Plate Example – 1280px.png';

import InstagramIcon from '../images/Icons/InstagramIcon.svg';
import FacebookIcon from '../images/Icons/FacebookIcon.svg';
import Linkedin from '../images/Icons/LinkedIn.svg';
import YouTube from '../images/Icons/YouTubeIcon.svg';

import albany_toyota_logo_horiz_charcoal from '../Images/11. Playbook/Albany Toyota Horizontal – Charcoal – 800x400.svg';
import albany_toyota_logo_horiz_primary from '../Images/11. Playbook/Albany Toyota Horizontal – Primary – 800x400.svg';
import albany_toyota_logo_horiz_red from '../Images/11. Playbook/Albany Toyota Horizontal – Red – 800x400.svg';

import albany_toyota_logo_stacked_charcoal from '../Images/11. Playbook/Albany Toyota Stacked – Charcoal – 800x400.svg';
import albany_toyota_logo_stacked_primary from '../Images/11. Playbook/Albany Toyota Stacked – Primary – 800x400.svg';
import albany_toyota_logo_stacked_red from '../Images/11. Playbook/Albany Toyota Stacked – Red – 800x400.svg';

import lgp_albany_toyota_logo_horiz_charcoal from '../images/11. Playbook/LGP Albany Toyota Horizontal – Charcoal – 800x400.svg'
import lgp_albany_toyota_logo_horiz_primary from '../images/11. Playbook/LGP Albany Toyota Horizontal – Primary – 800x400.svg'
import lgp_albany_toyota_logo_horiz_red from '../images/11. Playbook/LGP Albany Toyota Horizontal – Red – 800x400.svg'

import lgp_albany_toyota_logo_stacked_charcoal from '../images/11. Playbook/LGP Albany Toyota Stacked – Charcoal – 800x400.svg'
import lgp_albany_toyota_logo_stacked_primary from '../images/11. Playbook/LGP Albany Toyota Stacked – Primary – 800x400.svg'
import lgp_albany_toyota_logo_stacked_red from '../images/11. Playbook/LGP Albany Toyota Stacked – Red – 800x400.svg'

import toyota_store_logos_car from '../images/11. Playbook/toyota_store_logos_car.png';
import toyota_store_logos_cheque from '../images/11. Playbook/toyota_store_logos_cheque.png';
import toyota_store_logos_internal_collateral from '../images/11. Playbook/toyota_store_logos_internal_collateral.png';
import toyota_store_logos_internal_collateral_01 from '../images/11. Playbook/toyota_store_logos_internal_collateral-01.png';
import toyota_store_logos_internal_collateral_02 from '../images/11. Playbook/toyota_store_logos_internal_collateral-02.png';
import toyota_store_logos_tent from '../images/11. Playbook/toyota_store_logos_tent.jpg';

import life_education_example from '../images/11. Playbook/Life Education Example.png';
import national_vs_store_logo_recommendation from '../images/11. Playbook/National vs Store Logo Recommendation.png';

import social_media_endframes from '../images/11. Playbook/Social Media Endframes – 800x400.png'
import store_logo_flags from '../images/11. Playbook/Store Logo Flags – 800x400.png';

import AlbanyToyotaBW from '../images/11. Playbook/Albany Toyota B&W.svg';
import AlbanyToyotaRW from '../images/11. Playbook/white and red logo.svg';

import lgp_store_brand_initative_01 from '../images/11. Playbook/LGP Store Brand Initiative Example 1 – 1280px.png'
import lgp_store_brand_initative_02 from '../images/11. Playbook/LGP Store Brand Initiative Example 2 – 1280px.jpg'
import lgp_store_brand_initative_03 from '../images/11. Playbook/LGP Store Brand Initiative Example 3 – 1280px.png'

import lgp_store_logo_01 from '../images/11. Playbook/LGP Store Logo Example 1 – 1280px.jpg'
import lgp_store_logo_02 from '../images/11. Playbook/LGP Store Logo Example 2 – 1280px.png'
import lgp_store_logo_03 from '../images/11. Playbook/LGP Store Logo Example 3 – 1280px.png'
import lgp_store_logo_04 from '../images/11. Playbook/LGP Store Logo Example 4 – 1280px.png'

import tnz_brand_activities_01 from '../images/11. Playbook/TNZ Brand Activities 01.jpg'
import tnz_brand_activities_02 from '../images/11. Playbook/TNZ Brand Activity Example 4 – 1280px.png'
import tnz_brand_activities_03 from '../images/11. Playbook/TNZ Brand Activity Example 3 – 1280px.jpg'

import incorrect_use from '../images/11. Playbook/Incorrect Logo Use – 1600x1000.png';

import StandardSignature from '../images/11. Playbook/Store Signature – Standard – 1280px.png';
import SignatureWithAwards from '../images/11. Playbook/Store Signature – Award – 1280px.png';

import hobsonville_lgp_stacked from '../images/11. Playbook/Hobsonville TOY_LGP Stacked - RGB.png'
import hobsonville_lgp_horizontal from '../images/11. Playbook/Hobsonville Toyota Horizontal - RGB.png'
import Checkerboard from '../components/v2/CheckerBoard';

const StorePlaybook = ({ location }) => {
  return (
    <Layout page="playbook" appLocation={location}>
      <div className="hero-img">
        <Image width="100%" src={LGP_Wishing_Chair_png} />
      </div>
      <PageSection sectionId="introduction">
        <ContentBlock>
          <div className="text">
            <p>
              The Toyota Store Playbook is a comprehensive guide designed to ensure all Toyota brand, marketing and product communications across Stores in New Zealand are unified and consistent. Here, you’ll find everything you need to create your own Toyota marketing materials, along with a guide on where and how to use our logos.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="brand-partnership">
        <ContentBlock whiteBg={true}>
          <h2>BRAND PARTNERSHIPS</h2>
          <div className="text">
            <p>
              To bring our <em>Let’s Go Places</em> proposition to life, we need
              to make it tangible and relatable to New Zealanders. That includes
              taking into consideration the kinds of activities we sponsor and
              who we partner with.
            </p>
            <p>
              Refer to the below to see how some of our existing brand-level
              partnerships ladder back to our three brand journeys.
            </p>
          </div>
        </ContentBlock>
        <BrandPartnership />
      </PageSection>
      <PageSection sectionId="local-sponsorships-and-partnerships">
        <ContentBlock>
          <h2>LOCAL SPONSORSHIPS AND PARTNERSHIPS</h2>
          <div className="text">
            <p>
              All communication and activity should be ‘on brand’ on a community
              level in terms of local partnerships and sponsorships. To ensure
              this, the three journeys should be used to guide the types of
              activity we’re involved with.
            </p>
            <p>
              <strong>
                Here are some key considerations when reviewing local proposals:
              </strong>
            </p>
            <ul>
              <li>
                Do the values of the proposed partner or initiative align with
                any of the <em>Let’s Go Places</em> journeys of Mobility,
                Community and Sustainability?
              </li>
              <li>
                Will our involvement portray Toyota as a progressive brand
                that’s working towards a greater New Zealand?
              </li>
              <li>
                What benefit does the proposed partnership and sponsorship bring
                to your Store?
              </li>
              <li>
                Does the partner align with any other conflicting brands or
                contentious activities? e.g. alcohol, other car brands,
                political parties.
              </li>
              <li>Does the partner have a positive reputation?</li>
              <li>
                What does the activity or partner contribute to the local
                community?
              </li>
            </ul>
            <p>
              Below are some examples of existing partnerships that are
              considered ‘on brand’:
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: 'white' }}
          images={[
            {
              image: {
                svg: community_moover,
                style: { height: '400px', width: 'auto' }
              },
              caption: {
                copy: '<strong>Community Moover</strong><br/><br/>The Ebbett Toyota Community Van ’mooves’ groups and organisations to games, competitions and other events. It seats 10 and is available free for up to 2 days.'
              }
            },
            {
              image: {
                svg: manukau_png,
                style: { height: '400px', width: 'auto' }
              },
              caption: {
                copy: '<strong>Manukau Toyota</strong> - Life Education Partnership<br/><br/>Manukau Toyota Group supports the Counties Manukau arm of Life Education Trust, one of New Zealand’s most respected child education and empowerment organisations.<br/><br/>The Trust works with primary schools in our region to give students the tools to understand issues around health and nutrition, as well as how to contribute positively to their local community.'
              }
            },
            {
              image: {
                svg: backing_the_hawks_bay,
                style: { height: '400px', width: 'auto' }
              },
              caption: {
                copy: '<strong>Backing the Bay Hawks</strong><br/><br/>As the Official Vehicle Partner of Bay Hawks basketball, Toyota Hawke’s Bay has the team travelling in style and comfort, no matter how tall they are!'
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PageSection sectionId="toyota-store-logos">
        <ContentBlock>
          <h2>Toyota Store Logos</h2>
          <div className="text">
            <p>
              To ensure consistency across all customer touch points, the following is a guide for how Stores are to use logos alongside their own branding and communications, which logo to use and when. No alterations are to be made to the layouts of the logos.
            </p>
            <p>
              These logos are available to access through SharePoint. Please reach out to the TNZ marketing team if you are unsure or need instructions on how to access.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="vehicles"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "scroller",
                images: [
                  {
                    image: {
                      svg: albany_toyota_logo_horiz_primary,
                    }
                  },
                  {
                    image: {
                      svg: albany_toyota_logo_horiz_charcoal,
                    }
                  },
                  {
                    image: {
                      svg: albany_toyota_logo_horiz_red,
                    }
                  },
                ]
              },
              label: "Horizontal"
            },
            {
              content: {
                style: "scroller",
                images: [
                  {
                    image: {
                      svg: albany_toyota_logo_stacked_primary,
                    }
                  },
                  {
                    image: {
                      svg: albany_toyota_logo_stacked_charcoal,
                    }
                  },
                  {
                    image: {
                      svg: albany_toyota_logo_stacked_red,
                    }
                  },
                ]
              },
              label: "Vertical"
            }
          ]}
        />
        <ContentBlock>
          <h3>Primary logos / Staging Platform</h3>
          <div className="text">
            <p>
              The staging platform is how we refer to the Toyota emblem when shown in the red box. The red box allows the emblem to be anchored, impactful and instantly recognisable as Toyota. Please do not create your own logo using the red box. Only use the logos provided.
            </p>
            <p>
              DO NOT use Owner Group Logos alongside a Toyota Logo. Owner group logos must not appear in marketing or customer facing collateral (including marquees, demo unit decals, advertising collateral, email signatures etc).
            </p>
          </div>
        </ContentBlock>
        <ContentBlock>
          <h3>Example artwork</h3>
          <div className="text">
            <p>Below are some examples of the correct usage of the Toyota Store Logo in its environment.</p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: '#fafafa', paddingTop: '0' }}
          images={[
            {
              image: {
                svg: toyota_store_logos_car,
              },
              caption: {
                copy: 'Vehicle Branding'
              }
            },
            {
              image: {
                svg: toyota_store_logos_tent,
                style: {
                  maxHeight: "480px"
                },
              },
              caption: {
                copy: 'Marquee Branding'
              }
            },
            {
              image: {
                svg: toyota_store_logos_cheque,
              },
              caption: {
                copy: 'Sponsorship Cheque'
              }
            },
            {
              image: {
                svg: toyota_store_logos_internal_collateral,
                style: {
                  maxHeight: "480px"
                }
              },
              caption: {
                copy: "Internal Letterhead Branding"
              }
            },
            {
              image: {
                svg: toyota_store_logos_internal_collateral_01,
              },
              caption: {
                copy: "Compliment Slip Branding"
              }
            },
            {
              image: {
                svg: toyota_store_logos_internal_collateral_02,
              },
              caption: {
                copy: "eDM Banner Artwork"
              }
            },
          ]}></ImageScroller>


        <ContentBlock whiteBg={true} noBottomSpacing={true}>
          <div className="text">
            <p>
              There may be some instances where a logo is to be applied to a
              space that is too narrow for existing logos to be used. See below
              for an example of an acceptable layout for this logo format.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid>
          <Image width="" src={number_plate_png}></Image>
          {/* <Image width="half" src={rugby_jersey_png}></Image> */}
        </ImageGrid>
        <ContentBlock whiteBg={true} noTopSpacing={true}>
          <div className="text">
            <p>
              In these instances there is scope to create an alternative logo layout with the Store name on one line to ensure the Store name is clearly visible. These logos should place the Store name to the right of the staging platform with text not exceeding the height of the Toyota emblem within the staging platform. See below for an example of acceptable colours and layouts for this logo format.
            </p>
            <p>
              Please note this logo format is only to be used when space dictates.
            </p>
          </div>
        </ContentBlock>
        <ImageGrid noBottomSpacing>
          <Image width="half" src={AlbanyToyotaRW}></Image>
          <Image width="half" src={AlbanyToyotaBW}></Image>
        </ImageGrid>
      </PageSection>
      <PageSection sectionId="lgp-store-logos">
        <ContentBlock whiteBg={false} noBottomSpacing>
          <h2><em>Let’s Go Places</em> Store Logos</h2>
          <h3><em>Let’s Go Places</em> Store Logo Lockup</h3>
          <div className="text">
            <p>The <em>Let’s Go Places</em> Store logo lockup is used for the consistency of marketing Store community activities e.g. partnerships and sponsorships through print, social media, in-store and event collateral.
            </p>
            <p>
              This logo can also be used for brand and brand owned initiatives.  Please refer to examples.
              It should never be used in conjunction with any other logo, nor should the size or placement of the <em>Let’s Go Places</em> be modified.
              The Stacked version is the recommendation as the primary logo. There may be some instances where a logo is to be applied to a space that is too narrow for the Stacked logos to be used. In this case, the Horizontal logo can be used.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="vehicles"
          backgroundColor="white"
          items={[
            {
              content: {
                style: "scroller",
                images: [
                  {
                    image: {
                      svg: lgp_albany_toyota_logo_stacked_primary,
                    }
                  },
                  {
                    image: {
                      svg: lgp_albany_toyota_logo_stacked_charcoal,
                    }
                  },
                  {
                    image: {
                      svg: lgp_albany_toyota_logo_stacked_red,
                    }
                  },
                ]
              },
              label: "Stacked"
            },
            {
              content: {
                style: "scroller",
                images: [
                  {
                    image: {
                      svg: lgp_albany_toyota_logo_horiz_primary,
                    }
                  },
                  {
                    image: {
                      svg: lgp_albany_toyota_logo_horiz_charcoal,
                    }
                  },
                  {
                    image: {
                      svg: lgp_albany_toyota_logo_horiz_red,
                    }
                  },
                ]
              },
              label: "Horizontal"
            },
          ]}
        />
        <ContentBlock>
          <h3>Example artwork</h3>
          <div className="text">
            <p>Examples of <em>Let’s Go Places</em> Store logo in use. If you would like to source any artwork files please email the Toyota marketing inbox,  <a href="mailto:marketing@toyota.co.nz">marketing@toyota.co.nz</a> to source the artwork files.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: 'white' }}
          images={[
            {
              image: {
                svg: lgp_store_logo_02,
                style: {
                  maxWidth: 800,
                }
              },
              caption: {
                copy: "Billboard Example 1"
              }
            },
            {
              image: {
                svg: lgp_store_logo_03,
                style: {
                  maxWidth: 800,
                }
              },
              caption: {
                copy: "Billboard Example 2"
              }
            },
            {
              image: {
                svg: lgp_store_logo_04,
                style: {
                  maxWidth: 800,
                }
              },
              caption: {
                copy: "Billboard Example 3"
              }
            },
            {
              image: {
                svg: lgp_store_logo_01,
                style: {
                  maxWidth: 800,
                }
              },
              caption: {
                copy: "Vehicle branding"
              }
            },
            {
              image: {
                svg: social_media_endframes,
                style: {
                  maxWidth: 800
                },
              },
              caption: {
                copy: 'Social media endframe.'
              }
            },
            {
              image: {
                svg: store_logo_flags,
                style: {
                  maxWidth: 800
                },
              },
              caption: {
                copy: 'Note: These flags would only be used at a sponsorship event – the Store logo would be used in stores.'
              }
            },
          ]}></ImageScroller>
        <ContentBlock noBottomSpacing>
          <div className="text">
            <h3>Store Brand initiatives
            </h3>
            <p className="text">
              Examples of Store Brand initiatives in use.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: '#fafafa' }}
          images={[
            {
              image: {
                svg: lgp_store_brand_initative_01,
                style: {
                  maxHeight: 800
                }
              },
              caption: {
                copy: "Brand and Brand Owned Initiatives"
              }
            },
            {
              image: {
                svg: life_education_example,
                style: {
                  maxHeight: 800
                }
              },
              caption: {
                copy: "Local Partnerships and Sponsorships"
              }
            },
            {
              image: {
                svg: lgp_store_brand_initative_03,
                style: {
                  maxHeight: 800
                }
              },
              caption: {
                copy: "Brand and Brand Owned Initiatives"
              }
            },
          ]} />
        <ContentBlock noBottomSpacing whiteBg>
          <h3>TNZ Brand activities</h3>
          <div className="text">
            <p>Examples of Brand initiatives in use.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: 'white' }}
          images={[
            {
              image: {
                svg: tnz_brand_activities_01,
                style: {
                  maxHeight: 800,
                }
              },
              caption: {
                copy: "Brand and Brand Owned Initiatives"
              }
            },
            {
              image: {
                svg: tnz_brand_activities_02,
                style: {
                  maxHeight: 800,
                }
              },
              caption: {
                copy: "Partnerships"
              }
            },
            {
              image: {
                svg: tnz_brand_activities_03,
                style: {
                  maxHeight: 800
                }
              },
              caption: {
                copy: "Product"
              }
            },
          ]} />
          <ContentBlock whiteBg={true} noTopSpacing>
          <h3>National vs Store logo recommendation</h3>
          <div className="text">
            <p>
              Below are examples for how logos should be used across National Brand and Store Brand
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: 'white', paddingTop: '0' }}
          images={[
            {
              image: {
                svg: national_vs_store_logo_recommendation,
                style: {
                  height: 'auto', maxHeight: '400px'
                }
              },
            },
            {
              image: {
                svg: life_education_example,
                style: {
                  height: 'auto', maxHeight: '400px'
                }
              },
              caption: {
                copy: 'Note: Sponsor logos to sit away from LGP / Store lockup.'
              }
            },
          ]}></ImageScroller>
        <ContentBlock whiteBg={true}>
          <h3>Incorrect Use</h3>
          <div className="text">
            <p>For both building brand awareness and protecting the trademark of our brand, it's important to retain consistent and correct use of all our logos. In general, do not alter any of the logos in any way. The following examples illustrate possible misuses and simple rules to follow. These examples apply across our entire suite of logos.</p>
          </div>
        </ContentBlock>
        <ImageGrid fullWidth={true}>
          <Image width="full" src={incorrect_use}></Image>
        </ImageGrid>

        <ContentBlock>
          <h3>Toyota Store logos vs <em>Let’s Go Places</em> Store logos</h3>
        </ContentBlock>
        <Checkerboard
          colour={hobsonville_lgp_horizontal}
          title="Toyota Store Logo"
          imgStyle={{ padding: "27%" }}
          whiteBg={true}
          text={
            [
              'Business cards',
              'Letterhead',
              'Compliment slips',
              'E-mail signatures',
              'All TUNZ communications e.g. purchase and sale agreements',
              'Vehicle branding',
              'Store flags',
              'eDM banner',
              'Store cheques and other in-store related promotional items'
            ]
          }
        />
        <Checkerboard
          // alt={true}
          whiteBg={true}
          colour={hobsonville_lgp_stacked}
          title="<em>Let’s Go Places</em> brand artwork"
          imgStyle={{ padding: "10%" }}
          text={
            [
              'Brand artwork e.g. Store billboards',
              'Store specific sponsorship artwork and advertising',
              'Vehicle sponsorship branding',
              'Event flags and collateral'
            ]}
        />
      </PageSection>
      <PageSection sectionId="store-email-signatures">
        <ContentBlock noBottomSpacing={true}>
          <h2>Store Email Signatures</h2>
          <div className="text">
            <p>
              Store email signatures have also been re-designed to reflect our
              new brand identity.
            </p>
            <p>
              All Store email communications should now use the signature format
              below with the noted formatting.
            </p>
          </div>
        </ContentBlock>
        <Tabs
          section="store-email-signature"
          backgroundColor="white"
          items={[
            {
              content: {
                style: 'image',
                image: {
                  svg: StandardSignature,
                  style: {
                    maxWidth: '1000px',
                    margin: 'auto',
                    padding: '40px 0'
                  }
                }
              },
              label: 'Standard'
            },
            {
              content: {
                style: 'image',
                image: {
                  svg: SignatureWithAwards,
                  style: {
                    maxWidth: '1000px',
                    margin: 'auto',
                    padding: '40px 0'
                  }
                }
              },
              label: 'Awards'
            }
          ]}
        />
      </PageSection>
      <PageSection sectionId="social-101">
        <ContentBlock whiteBg={true}>
          <h2>Social 101</h2>
          <div className="text">
            <p>
              If you don’t have a presence on social media, you are missing an
              opportunity to reach and engage with your customers and the local
              community. Social media channels across all platforms provide you
              with an opportunity to build relationships, online communities,
              and brand awareness.
            </p>
            <p>
              There are many platforms that at a Store level you can activate
              your brand through including the following main platforms.
            </p>
          </div>
        </ContentBlock>
        <div className="social-grid">
          <Socialpreveiw
            svg={FacebookIcon}
            title="Facebook"
            text="is used for sharing multiple types of content to
            engage and inform your audience."
          />
          <Socialpreveiw
            svg={InstagramIcon}
            title="Instagram"
            text="is used as a visual content platform to provide
            insights into your brand. It is about having eye catching content in
            order to get follower engagement."
          />
          <Socialpreveiw
            svg={Linkedin}
            title="LinkedIn"
            text="is used to make professional connections or to
            connect with industry leaders and businesses to share helpful
            information about your brand."
          />
          <Socialpreveiw
            svg={YouTube}
            title="YouTube"
            text="is a video-based platform that is used to share
            longer form videos with your audience. From how-to videos to brand
            love videos, you can provide your audience with the knowledge they
            actively seek about your brand."
          />
        </div>
        <ContentBlock whiteBg={true} noBottomSpacing>
          <div className="text">
            <p>
              Below we have provided the key things to make your Facebook and
              Instagram channels successful, as these are the most popular
              channels that businesses use to elevate their brand presence. If
              you want to know anything further about LinkedIn or YouTube,
              please reach out to the Toyota New Zealand (TNZ) social media
              team.
            </p>
          </div>
        </ContentBlock>
        <ContentBlock whiteBg>
          <SocialGuide />
        </ContentBlock>
      </PageSection>
      <PrevNextNavFooter />
    </Layout >
  );
};

export default StorePlaybook;
