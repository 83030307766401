import React from 'react';
import placeholderImg from '../../images/placeholder_500x500.png';

const Checkerboard = (props) => {
  let classes = 'checkerboard';
  if (props.alt) {
    classes += ' alt';
  }
  if (props.whiteBg) {
    classes += ' white-bg';
  }

  const writeCopy = (text) => {
    if (typeof text === 'object')
      return text.map((p, i) => {
        return (
          <li className="text" key={i}>
            {p}
          </li>
        );
      });
    if (typeof text === 'string')
      return (
        <p
          className="text"
          dangerouslySetInnerHTML={{ __html: props.text }}></p>
      );
  };

  return (
    <div className={classes}>
      <div className="wrap">
        <div className="start">
          <div className="image" style={props?.imgStyle}>
            {!props.placeholder ? (
              <img alt="" src={props.colour} />
            ) : (
              <img src={placeholderImg} className="placeholderImg" />
            )}
          </div>
        </div>

        <div className="end">
          <div className="copy-wrap">
            <h3 className="title" dangerouslySetInnerHTML={{ __html: props.title }}></h3>
            {writeCopy(props.text)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkerboard;
