import React from 'react';
import Mobility_Start from '../../images/11. Playbook/Mobility_StartYourImpossible.jpg';
import Mobility_ETNZ from '../../images/11. Playbook/Brand_Partnerships_Mobility.png';
import sustainability from '../../images/11. Playbook/Brand_Partnerships_Sustainability.png';
import Community_01 from '../../images/11. Playbook/All Partnerships – RYDA – 746x400.jpg';
import Community_02 from '../../images/11. Playbook/All Partnerships – Surf Lifesaving – 746x400.jpg';

import Flickity from 'react-flickity-component';
const flickityOptions = {
  initialIndex: 0,
  wrapAround: true
};

const BrandPartnership = () => {
  return (
    <section className="brand-partnership">
      <div className="banner">
        <div className="top">
          <h4>
            <em>Let’s Go Places</em>
          </h4>
          <h6>
            We’re moving forward together to create a greater New Zealand for
            everyone
          </h6>
        </div>
        <div className="bot">
          <p>
            By aligning with <b>partners that share our values</b> and help us
            with our goal of creating a greater New Zealand
          </p>
        </div>
      </div>

      <div className="cards">
        <div className="card-wrap">
          <h4>MOBILITY</h4>
          <div className="card">
            <p>
              We aim to make mobility available to everyone, with accessible,
              affordable, safe innovation and technology that enhances New
              Zealanders’ lives.
            </p>
          </div>
          <Flickity className={'slider'} options={flickityOptions}>
            <div className="card">
              <div className="image">
                <img src={Mobility_Start} alt="Mobility_Start" />
              </div>
              <p>
                <b>Start Your Impossible</b>
              </p>
              <p>
                Toyota is an Official Worldwide Olympic and Paralympic Partner.
                By supporting both Olympians and Paralympians, our partnership
                demonstrates our desire to inspire the world to move and achieve
                ‘mobility for all’.
              </p>
            </div>
            <div className="card">
              <div className="image">
                <img src={Mobility_ETNZ} alt="Mobility_ETNZ" />
              </div>
              <p>
                <b>Emirates Team New Zealand</b>
              </p>
              <p>
                Toyota has been a supporter of Emirates Team New Zealand for
                almost three decades. This ongoing sponsorship associates us
                with tech innovation, bravery and pushing boundaries within the
                Mobility space.
              </p>
            </div>
          </Flickity>
        </div>
        <div className="card-wrap">
          <h4>SUSTAINABILITY</h4>
          <div className="card">
            <p>
              We care about being a sustainable business with sustainable
              practises and supporting programmes and initiatives to look after
              our New Zealand environment.
            </p>
          </div>
          <div className="card">
            <div className="image">
              <img src={sustainability} alt="sustainability" />
            </div>
            <p>
              <b>Chase Zero</b>
            </p>
            <p>
              As part of our commitment to sustainability, we worked with Emirates Team New Zealand to develop Chase Zero - the world’s first ever Hydrogen-powered foiling chase boat. The 10m long catamaran is powered by two 80kW Toyota Hydrogen Fuel Cells, one in each hull.
            </p>
          </div>
        </div>
        <div className="card-wrap">
          <h4>COMMUNITY</h4>
          <div className="card">
            <p>
              We back positive progress in the New Zealand Community through our
              own behaviours, community involvement, sponsorships, and
              inclusivity of all New Zealanders.
            </p>
          </div>
          <Flickity className={'slider'} options={flickityOptions}>
            <div className="card">
              <div className="image">
                <img src={Community_01} alt="Community_01" />
              </div>
            </div>
            <div className="card">
              <div className="image">
                <img src={Community_02} alt="Community_02" />
              </div>

            </div>
          </Flickity>
          <div className="card">
            <p>
              <b>All Partnerships</b>
            </p>
            <p>
              All of our brand-level partnerships demonstrate our desire for
              positive progress within the New Zealand Community. Whether it’s
              through encouraging inclusivity with our Paralympic sponsorship,
              through boosting positivity with Start Your Impossible and
              Emirates Team New Zealand, or through protecting our natural
              environment for future generations.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandPartnership;
