import React from 'react';

const SocialPreveiw = (props) => {
  return (
    <div className="social-preveiw">
      <div className="social-svg">
        {props.svg ? <img src={props.svg} height={120} /> : null}
      </div>
      <div className="social-text">
        <b>{props.title}</b> {props.text}
      </div>
    </div>
  );
};

export default SocialPreveiw;
