import React from 'react';

const SocialGuide = () => {
  return (
    <>
      <section className="social-guide-grid">
        <div className="col-title fb" style={{ gridArea: 'fb' }}>
          <div className="square" />
          <h4>Facebook</h4>
        </div>
        <div className="container" style={{ gridArea: 'fb1' }}>
          <div className="category">
            <h4>CONTENT</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                The key to Facebook is consistent content, putting together a
                content calendar with key dates is a good start.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Keeping across the TNZ pages also will inspire some ideas and
                gives you content that you can easily share.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Check with TNZ around creative for big campaigns that will be
                useful to share or adapt at a Store level, rather than
                recreating content.
              </p>
            </li>
          </ul>
        </div>
        <div className="container" style={{ gridArea: 'fb2' }}>
          <div className="category">
            <h4>CAMPAIGNS</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                Notify the TNZ social team of any Store campaigns and initiatives that we can support and share through our channels. This helps create a larger audience base, and hopefully some new followers!
              </p>
            </li>

            <li className="text-item">
              <p>•</p>
              <p>
                Tag @toyotaNZ in so we can engage with your content and boost
                your post’s relevance to a wider audience – utilise our large
                following.
              </p>
            </li>
          </ul>
        </div>
        <div className="container" style={{ gridArea: 'fb3' }}>
          <div className="category">
            <h4>HASHTAGS</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                Include strategic use of a limited number of hashtags in FB,
                particularly during campaigns like #StartYourImpossible.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Include the #toyotanz hashtag in posts so we can see when you
                have shared it and comment, like or share.
              </p>
            </li>
          </ul>
        </div>
        <div className="container" style={{ gridArea: 'fb4' }}>
          <div className="category">
            <h4>ENGAGE</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                React and/or reply to comments made on Facebook threads about
                your brand.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Even negative comments are a chance to provide an alternative
                viewpoint rather than leaving it there unanswered for others to
                see.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                If customers are taking the time to engage, they like it when
                you respond to them – this helps build your online community.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Comment on other pages’ posts such as local community
                sponsorships, partnerships, or your ambassadors – you never know
                you may gain some new followers by doing this.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                When signing on ambassadors, consider what their social media
                presence is and include a clause in their contract that requires
                them to give your brand social media content as part of the deal
                i.e., 2 posts per month.
              </p>
            </li>
          </ul>
        </div>
        <div className="col-title ig" style={{ gridArea: 'ig' }}>
          <div className="square" />
          <h4>Instagram</h4>
        </div>
        <div className="container ig" style={{ gridArea: 'ig1' }}>
          <div className="category">
            <h4>CONTENT</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                If you are not already on Instagram, you should open an account.
                It has high follower growth with more engagement and advocacy
                than Facebook.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Instagram is also the best place to reach a younger, newer
                consumer audience.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Instagram requires eye catching content, so when planning
                photography shoots it pays to keep this in mind
              </p>
            </li>
          </ul>
        </div>
        <div className="container ig" style={{ gridArea: 'ig2' }}>
          <div className="category">
            <h4>CAMPAIGNS</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                Tag @toyota_nz on story posts so that we can easily share with a
                tap. Anything TNZ share ultimately gets views back to the
                original story post – sometimes in significant numbers which
                will help in gaining followers quickly.
              </p>
            </li>
          </ul>
        </div>

        <div className="container ig" style={{ gridArea: 'ig3' }}>
          <div className="category">
            <h4>HASHTAGS</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                A greater number of hashtags can be included in Instagram posts
                but be sure to check the content it’s been used for on other
                posts, so it aligns with brand values.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                The recommended number of hashtags on Instagram are 6-8, and try
                to use some popular ones like #carsofinstagram to generate more
                followers
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Include the #toyotanz in posts so that we are notified and can
                like, comment, and share posts onto our story feed
              </p>
            </li>
          </ul>
        </div>
        <div className="container ig" style={{ gridArea: 'ig4' }}>
          <div className="category">
            <h4>ENGAGE</h4>
          </div>
          <ul className="text-box">
            <li className="text-item">
              <p>•</p>
              <p>
                Engage with people that @mention your Store to encourage further
                @mentions.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                This can be as simple as liking a post or reacting to a story –
                but make sure what you’re liking aligns with brand values first.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                If customers are taking the time to engage, they like it when
                you respond to them – this helps build your online community.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                Comment on other pages’ posts such as local community
                sponsorships, partnerships, or your ambassadors – you never know
                you may gain some new followers by doing this.
              </p>
            </li>
            <li className="text-item">
              <p>•</p>
              <p>
                When signing on ambassadors, consider what their social media
                presence is and include a clause in their contract that requires
                them to give your brand social media content as part of the deal
                i.e., 2 posts per month.
              </p>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default SocialGuide;
